@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0px;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.login-main {
  background: #f7fafe;
}
.login-bg {
  background: url("../src/images/bg.png") no-repeat center top;
  height: 86vh;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding: 0px 80px;
}

.align-box h1 {
  color: #fff;
  font-weight: 200;
}

.align-box h1 b {
  font-weight: 700;
}

.login-btn {
  border: 2px solid #ffffff;
  border-radius: 30px;
  color: #fff;
  text-decoration: none;
  width: 200px;
  font-size: 18px;
  text-transform: uppercase;
  padding: 8px;
}

.login-btn:hover {
  color: #167ecf;
  background: #fff;
}

.logo-right {
  text-align: center;
}

.logo-right {
  text-align: center;
}

.logo-right h3 {
  font-size: 23px;
  font-weight: 400;
}

.login-control {
  border: 1px solid #d4d4d4;
  border-radius: 35px;
  height: 45px;
  padding: 0px 50px;
  color: #176ae6 !important;
}

.login-control:focus {
  box-shadow: none;
  border-color: #176ae6;
}

span.icon-lock {
  position: absolute;
  right: 22px;
  top: 12px;
}

.email-icon,
.lock-icon {
  position: absolute;
  top: 12px;
  left: 18px;
}

.login-control::placeholder {
  color: #176ae6 !important;
}
.btn-primary {
  color: #fff;
  background-color: #0074ce;
  border-color: #0074ce;
}

.sidebar {
  width: 380px;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 0px 19px #00000014;
  border-radius: 0px 15px 15px 0px;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
}
.content {
  margin-left: 375px;
  background: #f7fafe url("../src/images/cover-bg.png") no-repeat center top;
  background-size: 100% 30%;
}

.dashboard {
  height: 100%;
}

.user-name h3 {
  color: #11141a;
  font-weight: 700;
  font-size: 24px;
}

.user-name button {
  color: #0074ce;
  padding: 0px;
  text-decoration: none;
  font-weight: 500;
}

.user-name button:focus {
  box-shadow: none;
}

.sidebar-menu {
  background: #e9f3ff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 10px;
}

.sidebar-menu ul li a {
  background: rgb(0 116 206 / 5%);
  border-radius: 15px;
  color: #4d4d4d;
  display: block;
  padding: 0.8rem 1.5rem;
  font-size: 1.25rem;
  text-decoration: none;
  font-weight: 500;
}

.sidebar-menu ul li {
  margin-bottom: 5px;
}

.sidebar-menu ul li a.active {
  background: #0074ce;
  color: #fff;
}

.sidebar-menu ul li a:hover {
  background: #0074ce;
  color: #fff;
}

.btn-log {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000012;
  color: #817c9b;
  text-decoration: none;
  padding: 5px 15px 5px 5px;
  font-weight: 500;
}

.btn-log span {
  background: #0074ce;
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 100%;
  line-height: 35px;
  margin-right: 10px;
}

.btn-log span img {
  width: 15px;
  height: 15px;
}

.btn-log:hover {
  background: #fff9f6;
}

.search-box .input-group {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000001c;
  border-radius: 25px;
}

.search-box .input-group .input-group-text {
  background: #fff;
  border: 0px;
  border-radius: 25px 0px 0px 25px;
  position: relative;
}

.search-box .input-group .form-control {
  border: 0px;
  border-radius: 0px 25px 25px 0px;
  color: #084298;
}

.search-box .input-group .input-group-text:before {
  border-right: 2px solid #eaeff7;
  content: "";
  position: absolute;
  right: 0px;
  height: 20px;
  width: 1px;
  z-index: 1;
}

.search-box .input-group .form-control:focus {
  box-shadow: none;
}

.header-title h2 {
  color: #001892;
  font-weight: 700;
  font-size: 28px;
}

.header-title h2 {
  color: #001892;
  font-weight: 700;
  font-size: 28px;
}

.blue-light {
  background: #d8fff6 0% 0% no-repeat padding-box;
  border-radius: 20px 0px 0px 20px;
  text-align: center;
}

.text-jourenty {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 26px #e1e1ff;
  border-radius: 20px;
}

.mb-lg-24-minus {
  margin-left: -24px;
}

.blue-light h2 {
  font-size: 21px;
  color: #0074ce;
  word-break: break-word;
  padding-right: 16px;
}

text-jourenty em {
  color: #707070;
}

.text-jourenty em {
  color: #707070;
  display: block;
  margin-bottom: 5px;
}

.text-jourenty p {
  font-weight: 400;
  font-size: 16px;
  color: #6d7278;
  min-height: 160px;
}

.text-jourenty a {
  color: #0074ce;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
}

.back span {
  display: inline-block;
  background: #0074ce;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
}

.back {
  position: absolute;
  top: 18px;
}

.user-edit {
  width: 90px;
  height: 90px;
}

.edit-icon {
  position: absolute;
  right: -9px;
  top: 2px;
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #e9f3ff;
  color: #0074ce;
  border-radius: 100%;
  line-height: 27px;
  cursor: pointer;
}

.edit-icon img {
  width: 15px;
  height: 15px;
}

.right-0 {
  right: 0px;
}

.password-box h3 {
  font-size: 18px;
  font-weight: 600;
}
.user-password .form-label {
  font-weight: 500;
}

.user-password .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000001c;
  border-radius: 25px;
  height: 45px;
  padding-left: 35px;
  border: 0px;
}

.user-password .form-label {
  font-weight: 500;
  margin-left: 5px;
}
.user-password .form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000001c;
  border-radius: 25px;
  height: 45px;
  padding-left: 42px;
}

span.lock {
  position: absolute;
  top: 12px;
  left: 13px;
  color: #11141a6b;
  border-right: 1px solid #bbc5d5;
}

span.lock svg {
  margin-right: 5px;
}

.gray-input::placeholder {
  color: #bbc5d5 !important;
}

.w-200 {
  width: 200px;
}

.btn:focus {
  box-shadow: none;
}

.edit-icon input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  cursor: pointer;
}
.editname {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000001c;
  border-radius: 25px;
  height: 45px;
  border: 0px;
  width: 85%;
}

.editname {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 14px #0000001c;
  border-radius: 25px;
  height: 45px;
  border: 0px;
  width: 85%;
  padding: 5px 12px;
  font-size: 1rem;
  outline: none;
  color: #bbc5d5;
}
.editname::placeholder {
  color: #bbc5d5 !important;
}

.blue-input::placeholder {
  color: #001892 !important;
  font-weight: 600;
}
.blue-input {
  color: #001892 !important;
  font-weight: 500;
  height: 45px;
}

header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 17px #00000029;
  position: relative;
  z-index: 1;
}

.user-profile-toggle {
  display: inline-block;
}

.user-profile-toggle img {
  width: 50px;
}

.user-profile-toggle {
  display: inline-block;
}

.user-profile-toggle img {
  width: 50px;
}

.dropdown-logo {
  font-size: 24px;
  font-weight: 600;
}

.dropdown-logo.dropdown-toggle::after {
  display: none;
}

.search-bg {
  background: #f7fafe url("../src/images/cover-bg.png") no-repeat center top;
  background-size: 100% 30%;
}
.radius-30 {
  border-radius: 30px;
}

.card-box p {
  margin: 0px;
  font-weight: 400;
  line-height: 1.6;
  color: #6d7278;
  font-size: 16px;
}

.card-box em {
  display: inline-block;
  margin-bottom: 10px;
  color: #7070708c;
  font-weight: 500;
  font-size: 16px;
}

.card-box h5 {
  color: #001892;
  font-weight: 700;
}

.gray-color-search {
  background: #e9f3ff;
}
.toggle-open {
  background: #0074ce 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #0000001f;
  border-radius: 0px 10px 10px 0px;
  display: inline-flex;
  width: 50px;
  height: 50px;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.openoffset {
  width: 380px;
  background: transparent;
  box-shadow: none;
  border: 0px;
}

.openoffset .offcanvas-header {
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 1rem;
  z-index: 1;
}

.card-box h5 a {
  color: #001892;
  text-decoration: none;
}

.blue-light-color {
  background: #e9f3ff 0% 0% no-repeat padding-box;
  border-radius: 15px;
}

.blue-light-color h6 {
  margin: 0px;
  color: #52575da6;
  font-weight: 600;
}

.blue-light-color h6 a {
  color: #52575da6;
  text-decoration: none;
}

.tabs-status {
  border: 1px solid #ededed;
  border-radius: 30px;
  padding: 5px;
}

.tabs-status a.active {
  background: #d8fff6 0% 0% no-repeat padding-box;
  border-radius: 25px;
  color: #001892;
}

.tabs-status a {
  color: #0074ce;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.light-blue-bg {
  background: #d8fff6 0% 0% no-repeat padding-box;
  border-radius: 20px;
}
img {
  max-width: 100%;
}
.arrow-journey {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001c;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  line-height: 33px;
  color: #0074ce;
}

.text-journey h6 {
  margin: 0px;
  color: #0074ce;
  font-weight: 600;
  font-size: 18px;
}

.text-journey p {
  color: #707070ab;
  font-size: 14px;
  font-weight: 500;
}

.light-blue-bg a {
  text-decoration: none;
  color: inherit;
}

.box-height {
  min-height: 620px;
}
@media (min-width: 992px) {
  .h-300 {
    height: 70vh;
  }
}

.complated {
  background: #0074ce;
  border-radius: 23px;
}

.complated-text {
  display: block;
  text-align: center;
  padding: 3px;
  color: #fff;
  font-weight: 500;
}

.backup {
  display: inline-flex;
  background: #0074ce;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.backup svg {
  width: 22px;
  height: 22px;
}
.card-box .backup a {
  color: #fff;
  text-decoration: none;
}

.descipton-text p {
  color: #272727;
  font-weight: 500;
}

.btn-tactic {
  background: #ff8d8d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid #ff4343;
  border-radius: 11px;
  color: #fff;
  font-weight: 500;
}

.font-big {
  font-weight: 700;
  font-size: 18px;
  color: #001892;
  display: inline-block;
}

.status p {
  font-size: 16px;
  font-weight: 600;
}

.btn-d3 {
  background: #e9f3ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000012;
  border: 1px solid rgb(0 116 206 / 76%);
  border-radius: 11px;
  color: rgb(0 116 206 / 76%);
  font-weight: bold;
}

.cricle-according-btn {
  border-radius: 15px !important;
  background: #e9f3ff 0% 0% no-repeat padding-box;
}

.cricle-according-btn .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
}

.cricle-according-btn .accordion-button {
  color: #001892;
  font-size: 18px;
  font-weight: 700;
  background: transparent;
}

.cricle-according-btn .accordion-body p {
  color: #272727;
  font-weight: 600;
}

.cricle-according-btn .accordion-button:after {
  width: 40px;
  background-color: #fff;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0px 3px 6px #0000001c;
  background-position: center;
  background-image: url("../src/images/according-arrow.svg");
  transform: rotate(272deg);
}

.cricle-according-btn .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}

.m-left {
  margin-left: -10px;
}

.m-right {
  margin-right: -10px;
}

.cricle-according-btn.white-toggle-bg {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.cricle-according-btn.white-toggle-bg .accordion-button:after {
  background-color: #e9f3ff;
  box-shadow: none;
}

.cricle-according-btn.white-toggle-bg {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.cricle-according-btn.white-toggle-bg .accordion-button:after {
  background-color: #e9f3ff;
  box-shadow: none;
}

.cricle-according-btn.white-toggle-bg .accordion-button {
  padding: 0.7rem 1.25rem;
}

.cricle-according-btn.white-toggle-bg .accordion-button {
  padding: 0.7rem 1.25rem;
}

.accordion-text h3 {
  border-bottom: 1px solid #e2e7ee;
  padding-bottom: 10px;
  font-size: 18px;
  color: #001892;
  font-weight: 600;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
}

.card-box {
  box-shadow: 0px 3px 17px #0000001c;
}
.coming-bg {
  height: 100vh;
  margin-left: 375px;
  background: #f7fafe;
}
@media (max-width: 992px) {
  .coming-bg {
    margin-left: 0px;
  }
  .blue-light {
    border-radius: 20px;
  }
  .content {
    margin: 0px;
  }
  .mb-lg-24-minus {
    margin: 0px;
  }
  .openoffset {
    width: 320px;
  }
  .sidebar {
    width: 320px;
  }
  .openoffset .offcanvas-header {
    margin-right: 0px;
  }

  .sidebar-menu ul li a {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .md-full button {
    width: 100%;
  }
}
